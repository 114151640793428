<template>
  <section class="m-favorite m-scroll" @scroll="handleScroll($event)">
    
    <!-- 头部导航 -->
    <m-header title="收藏">
      <span v-if="!showAll" slot="right" @click="handleEdit">编辑</span>
      <span v-else          slot="right" @click="HandleAll">完成</span>
    </m-header>

    <!-- 关注列表 -->
    <van-list  v-model="loading" :immediate-check="false"	  :finished="finished" finished-text="暂无数据" @load="onLoad" class="list">
     
      <van-swipe-cell v-for="(it,index) in data" :key="index" :disabled="disabled">

        <van-checkbox v-model="it.checked" ref="item" checked-color="#ED2A24" class="item-check">
          <div class="item-wrap" >
            <div class="avatar" @click.stop.prevent="$router.push({path: `/zh/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">
              <img :src="$store.state.imgPreUrl + it.image_url" alt="img">
              <div class="eleven" v-if="it.merchant_id == 1 && it.pre_info.is_pre == 0 && elevenShow"></div>
            </div>
            <div class="info van-hairline--bottom">
              <p @click.stop.prevent="$router.push({path: `/zh/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">{{it.goods_info.name}}</p>
              <div>
                <div>
                  <p v-if="it.pre_info.pre_min_price != null">
                      <span>$</span><span>{{it.pre_info.pre_min_price}}</span>
                    </p>
                    <p v-else>
                      <span>$</span><span>{{(it.activity_min_price *1).toFixed(0) != 0 ? it.activity_min_price : (it.min_price *(it.off == 0 ? 1 : it.off/100)).toFixed(2)}}</span>
                      <span v-if="(it.activity_min_price *1).toFixed(0) != 0 ? true : it.off!= 0">${{it.min_price}}</span>
                    </p>
                </div>
                <!-- <img src="@/assets/index/iocn-gouwu@2x(1).png" alt=""> -->
              </div>
            </div>
            
          </div>
      </van-checkbox>
        <template #right>
          <router-link :to="`/zh/merchant/index/${it.merchant_id}`" class="go-merchant">去店铺</router-link>
          <span class="delete" @click="delFavoriteHandle(it.id, index)">删除</span>
        </template>
      </van-swipe-cell>

    </van-list>

    <!-- 底部全选 取消关注 -->
    <transition name="fade">
      <div v-show="showAll" class="fixed-bar m-padding">
          <van-checkbox v-model="checkedAll" @click="handleCheckAll" checked-color="#ED2A24">全选</van-checkbox>
          <span @click="onClear">取消收藏</span>
      </div>
    </transition>

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

import { getFavorite, favoriteGoods } from '@/api/zh/mine.js'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      tab:0,
      data:[],
      loading:false,
      finished:false,
      showAll:false,     // 是否显示全选
      disabled:false,    // 是否禁止滑动
      filter:false,      // 筛选弹窗 
      filterType:0,
      page: 1,
      islock: false,
      elevenShow: false // 圣诞活动边框显示
    }
  },
  computed: {
    checkedAll: {
      set(){
        return false
      },
      get(){
        let bol = false
        this.data.forEach(e => {
          if(!e.checked){
            bol = true
          }
        })
        if(bol){
          return false
        }else{
          return true
        }
      }
    },
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.islock = true
          this.page++
          this.getFavoriteHandle()
        }
      }
    },
    // 加载列表
    onLoad(){
      console.log('加载')
      let timer = setTimeout( ()=> {
        this.loading = false
        if(this.data >= 12){
          this.finished = true
          clearTimeout(timer)
        }
      },1500)
      
    },
    getFavoriteHandle() {
      // 获取收藏
      getFavorite({page: this.page}).then(res => {
        this.loading = false
        this.islock = false
        if(res) {
          res.data.data.forEach(el => {
            el.checked = false
          })
          this.data = this.data.concat(res.data.data)
        } else {
          this.finished = true
        }
      })
    },
    // 点击头部编辑
    handleEdit(){
      this.showAll  = true 
      this.disabled = true
      if(this.$refs.item != undefined) {
        this.$refs.item.forEach(e => {
          e.$el.style.left = '0px'
        })
      }
    },
    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击头部完成
    HandleAll(){
      this.showAll  = false 
      this.disabled = false
      if(this.$refs.item != undefined) {
        this.$refs.item.forEach(e => {
        e.$el.style.left = '-40px'
      })
      }
      this.checkedAll = false
      this.data.forEach(e => {
        e.checked = false
      })
    },
    // 点击全选
    handleCheckAll(){
      if(this.checkedAll){
        this.data.forEach(e => {
          e.checked = false
        })
      }else{
        this.data.forEach(e => {
          e.checked = true
        })
      }
    },
    // 点击头部编辑
    onClear(){
      // 判断是否时全选
        this.$dialog.confirm({
          title: '清除所有',
          message: '确定要清除所选收藏吗？',
        }) .then(() => {
          let idList = []
          this.data.forEach(el => {
            if(el.checked) {
              idList.push(el.id)
            }
          })
          favoriteGoods(idList,{status: 2}).then(res => {
            if(res) {
              this.$notify({ type: 'success', message: res.msg });
              this.data = []
              this.page = 1
              this.HandleAll()
              this.getFavoriteHandle()
            } else {
              this.$notify({ type: 'warning', message: res.msg });
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    
    // 单个删除
    delFavoriteHandle(id, index) {
      // 删除收藏
      favoriteGoods(id, {status: 2}).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg });
          this.data.splice(index, 1)
        }else{
          this.$notify({ type: 'warning', message: res.msg });
        }
      })
    }
  },
  created() {
    this.getFavoriteHandle()
  },
}
</script>

<style lang="less" scoped>
@import './favorite.less';
</style>